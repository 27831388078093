import { css } from "@emotion/core"
import dayPointer from "../images/dayPointer.gif"

export const TidesStyle = css`
  table.tide {
    padding: 0;
    width: 100%;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    border-collapse: collapse;
    float: left;
    th {
      text-align: left;
      border-bottom: #9abdcb 1px solid;
      padding: 0.6em 0 0.3em 0;
      margin: 0;
    }
    td {
      border-bottom: solid 1px #e2e2e2;
      font-size: 1em;
      height: 25px;
      margin: 0;
    }
  }

  table.tide tr.tideToday {
    text-align: left;
    border-bottom: #9abdcb 1px solid;
    padding: 0.6em 0 0.3em 0;
    margin: 0;
    background: #9abdcb;
  }

  table.tide tr.tideWeekend {
    text-align: left;
    border-bottom: 0px;
    padding: 0.6em 0 0.3em 0;
    margin: 0;
    background-color: #e5f5fe;
  }

  table.tide td.rules {
    border-bottom: solid 1px #e2e2e2;
    font-size: 1em;
    height: 25px;
    margin: 0;
    font-weight: bold;
  }

  table.tide tr.tideToday td.tideTodayp {
    border-bottom: solid 1px #fff;
    font-size: 1em;
    height: 25px;
    margin: 0;
    background: transparent url(${dayPointer}) no-repeat left center;
    width: 28px;
  }

  table.tide tr.tideWeekend td.tideTodayEmpty {
    border: 0px;
    font-size: 1em;
    height: 25px;
    margin: 0;
    width: 28px;
    background: #ffffff;
  }

  table.tide td.tideTodayEmpty {
    border: 0px;
    font-size: 1em;
    height: 25px;
    margin: 0;
    width: 28px;
    background: #ffffff;
  }
  .previous {
    padding-right: 0.5em;

  }

  .center {
    padding-left: 160px;
    line-height: 40px;
  }

  .next {
    padding-left: 0.5em;
    display: block;
    padding: 0;
    margin: 0;
    line-height: 40px;
    width:100%;
    text-align:right;
    float:none;
    clear:left;
  }

  .tidesbutton {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
  }
  .tidesbuttonright {
    float:right;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
  }
  .topbuttongroup {
    margin-top:20px;
    height:40px;
  }
  .tidegreen {
  background-color:lightgreen;
  }
  .tidered {
  background-color:pink;
  }
  .tideyellow {
    background-color:yellow;
    font-weight:bold;
  }

`
