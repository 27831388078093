import React, { useState, useEffect } from 'react';
import { TidesStyle } from "../style/tidesStyle"

const OpeningTimes2 = () => {




      

  return (


    <div css={TidesStyle}>
 

<table className="tide"  id="tide">
<tr>
    <th colSpan={2}>January 2025</th>
    <th colSpan={3}>Morning</th>
    <th colSpan={3}>Afternoon</th>
</tr>
<tr>
    <th>Date</th>
    <th>Day</th>
    <th>Open</th>
    <th>High</th>
    <th>Closed</th>
    <th>Open</th>
    <th>High</th>
    <th>Closed</th>

  </tr>

<tr>
  <td>1</td>	
  <td>Wednesday</td>	
  <td className='tidegreen'>04:06	</td>
  <td>07:06	</td>
  <td  className='tidered'>10:06	</td>
  <td className='tidegreen'>16:31	</td>
  <td>19:31	</td>
  <td className='tidered'>22:31</td>
</tr>

<tr>
<td>2</td>
<td>Thursday</td>	
<td  className='tidegreen'>04:48	</td>
<td>07:48	</td>
<td className='tidered'>10:48	</td>
<td className='tidegreen'>17:13	</td>
<td>20:13	</td>
<td className='tidered'>23:13</td>
</tr>

<tr>
<td>3	</td>
<td>Friday</td>	
<td className='tidegreen'>05:30	</td>
<td>08:30	</td>
<td className='tidered'>11:30	</td>
<td className='tidegreen'>17:55	</td>
<td>20:55	</td>
<td className='tidered'>23:55</td>
</tr>

<tr className='tideWeekend'>
<td>4	</td>
<td>Saturday</td>	
<td className='tidegreen'>06:13	</td>
<td>09:13	</td>
<td className='tidered'>12:13	</td>
<td className='tidegreen'>18:39	</td>
<td>21:39	</td>
<td className='tidered'>00:39 (+1)</td>
</tr>

<tr className='tideWeekend'>
<td>5	</td>
<td>Sunday</td>	
<td className='tidegreen'>06:57	</td>
<td>09:57	</td>
<td className='tidered'>12:57	</td>
<td className='tidegreen'>19:23	</td>
<td>22:23	</td>
<td className='tidered'>01:23 (+1)</td>
</tr>

<tr>
<td>6	</td>
<td>Monday</td>	
<td className='tidegreen'>07:43	</td>
<td>10:43	</td>
<td className='tidered'>13:43	</td>
<td className='tidegreen'>20:11	</td>
<td>23:11	</td>
<td className='tidered'>02:11 (+1)</td>
</tr>

<tr>
<td>7</td>	
<td>Tuesday	</td>
<td className='tidegreen'>08:35	</td>
<td>11:35	</td>
<td className='tidered'>14:35	</td>
<td  className='tideyellow' colSpan={3}>View Next Day</td>		
</tr>

<tr>
<td>8	</td>
<td>Wednesday</td>	
<td className='tidegreen'>21:07 (-1)	</td>
<td>00:07	</td>
<td className='tidered'>03:07	</td>
<td className='tidegreen'>09:38	</td>
<td>12:38	</td>
<td className='tidered'>15:38</td>
</tr>

<tr>
<td>9	</td>
<td>Thursday</td>	
<td className='tidegreen'>22:15 (-1)	</td>
<td>01:15	</td>
<td className='tidered'>04:15	</td>
<td className='tidegreen'>10:53	</td>
<td>13:53	</td>
<td className='tidered'>16:53</td>
</tr>

<tr>
<td>10	</td>
<td>Friday	</td>
<td className='tidegreen'>23:32 (-1)	</td>
<td>02:32	</td>
<td className='tidered'>05:32	</td>
<td className='tidegreen'>12:13	</td>
<td>15:13	</td>
<td className='tidered'>18:13</td>
</tr>


<tr className='tideWeekend'>
<td >11	</td>
<td>Saturday</td>	
<td className='tidegreen'>00:46	</td>
<td >03:46	</td>
<td className='tidered'>06:46	</td>
<td className='tidegreen'>13:25	</td>
<td>16:25	</td>
<td className='tidered'>19:25</td>
</tr>

<tr className='tideWeekend'>
<td>12	</td>
<td>Sunday	</td>
<td className='tidegreen'>01:50	</td>
<td>04:50	</td>
<td className='tidered'>07:50	</td>
<td className='tidegreen'>14:25	</td>
<td>17:25	</td>
<td className='tidered'>20:25</td>
</tr>

<tr>
<td>13	</td>
<td>Monday	</td>
<td className='tidegreen'>02:45	</td>
<td>05:45	</td>
<td className='tidered'>08:45	</td>
<td className='tidegreen'>15:18	</td>
<td>18:18	</td>
<td className='tidered'>21:18</td>
</tr>

<tr>
<td>14	</td>
<td>Tuesday	</td>
<td className='tidegreen'>03:33	</td>
<td>06:33	</td>
<td className='tidered'>09:33	</td>
<td className='tidegreen'>16:03	</td>
<td>19:03	</td>
<td className='tidered'>22:03</td>
</tr>

<tr>
<td>15	</td>
<td>Wednesday</td>	
<td className='tidegreen'>04:16	</td>
<td>07:16	</td>
<td className='tidered'>10:16	</td>
<td className='tidegreen'>16:44	</td>
<td>19:44	</td>
<td className='tidered'>22:44</td>
</tr>

<tr>
<td>16	</td>
<td>Thursday</td>	
<td className='tidegreen'>04:55	</td>
<td>07:55	</td>
<td className='tidered'>10:55	</td>
<td className='tidegreen'>17:21	</td>
<td>20:21	</td>
<td className='tidered'>23:21</td>
</tr>

<tr>
<td>17</td>	
<td>Friday</td>	
<td className='tidegreen'>05:30	</td>
<td>08:30	</td>
<td className='tidered'>11:30	</td>
<td className='tidegreen'>17:55	</td>
<td>20:55	</td>
<td className='tidered'>23:55</td>
</tr>

<tr className='tideWeekend'>
<td>18	</td>
<td>Saturday</td>	
<td className='tidegreen'>06:03	</td>
<td>09:03	</td>
<td className='tidered'>12:03	</td>
<td className='tidegreen'>18:26	</td>
<td>21:26	</td>
<td className='tidered'>00:26 (+1)</td>
</tr>

<tr className='tideWeekend'>
<td>19	</td>
<td>Sunday	</td>
<td className='tidegreen'>06:34	</td>
<td>09:34	</td>
<td className='tidered'>12:34	</td>
<td className='tidegreen'>18:56	</td>
<td>21:56	</td>
<td className='tidered'>00:56 (+1)</td>
</tr>
<tr>
<td>20	</td>
<td>Monday	</td>
<td className='tidegreen'>07:04	</td>
<td>10:04	</td>
<td className='tidered'>13:04	</td>
<td className='tidegreen'>19:26	</td>
<td>22:26	</td>
<td className='tidered'>01:26 (+1)</td>
</tr>

<tr>
<td>21	</td>
<td>Tuesday	</td>
<td className='tidegreen'>07:37	</td>
<td>10:37	</td>
<td className='tidered'>13:37	</td>
<td className='tidegreen'>20:02	</td>
<td>23:02	</td>
<td className='tidered'>02:02 (+1)</td>
</tr>

<tr>
<td>22	</td>
<td>Wednesday</td>	
<td className='tidegreen'>08:18	</td>
<td>11:18	</td>
<td className='tidered'>14:18	</td>
<td className='tidegreen'>20:52	</td>
<td>23:52	</td>
<td className='tidered'>02:52 (+1)</td>
</tr>

<tr>
<td>23	</td>
<td>Thursday</td>	
<td className='tideyellow' colSpan={3}>View Last Day</td>			
<td className='tidegreen'>09:17	</td>
<td>12:17	</td>
<td className='tidered'>15:17</td>

</tr>

<tr>
<td>24	</td>
<td>Friday	</td>
<td className='tidegreen'>10:08 (-1)	</td>
<td>01:08	</td>
<td className='tidered'>04:08	</td>
<td className='tidegreen'>10:45	</td>
<td>13:45	</td>
<td className='tidered'>16:45</td>
</tr>

<tr className='tideWeekend'>
<td>25	</td>
<td>Saturday</td>	
<td className='tidegreen'>11:38 (-1)	</td>
<td>02:38	</td>
<td className='tidered'>05:38	</td>
<td className='tidegreen'>12:10	</td>
<td>15:10	</td>
<td className='tidered'>18:10</td>
</tr>

<tr className='tideWeekend'>
<td>26</td>	
<td>Sunday	</td>
<td className='tidegreen'>00:47</td>	
<td>03:47	</td>
<td className='tidered'>06:47	</td>
<td className='tidegreen'>13:15	</td>
<td>16:15	</td>
<td className='tidered'>19:15</td>
</tr>

<tr>
<td>27</td>	
<td>Monday</td>	
<td className='tidegreen'>01:41	</td>
<td>04:41	</td>
<td className='tidered'>07:41	</td>
<td className='tidegreen'>14:08	</td>
<td>17:08	</td>
<td className='tidered'>20:08</td>
</tr>
<tr>
  <td>28</td>
  <td>Tuesday</td>	
  <td className='tidegreen'>02:29	</td>
  <td>05:29	</td>
  <td className='tidered'>08:29	</td>
  <td className='tidegreen'>14:55	</td>
  <td>17:55	</td>
  <td className='tidered'>20:55</td>
</tr>
<tr>
<td>29	</td>
<td>Wednesday</td>	
<td className='tidegreen'>03:15	</td>
<td>06:15	</td>
<td className='tidered'>09:15	</td>
<td className='tidegreen'>15:40	</td>
<td>18:40	</td>
<td className='tidered'>21:40</td>
</tr>

<tr>
<td>30	</td>
<td>Thursday</td>	
<td className='tidegreen'>03:58	</td>
<td>06:58	</td>
<td className='tidered'>09:58	</td>
<td className='tidegreen'>16:23	</td>
<td>19:23	</td>
<td className='tidered'>22:23</td>
</tr>
<tr>
<td>31	</td>
<td>Friday	</td>
<td className='tidegreen'>04:40	</td>
<td>07:40	</td>
<td  className='tidered'>10:40	</td>
<td className='tidegreen'>17:04	</td>
<td>20:04	</td>
<td className='tidered'>23:04</td>
</tr>



</table>



    </div>
  );
}

export default OpeningTimes2;
